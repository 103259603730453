/**
 * Defines appearance of each player's component
 */

.player_component {
  margin-left:25%;
}

.player_slider_container {
    width: 75%;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

.seek_slider,
.volumeSlider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 5px;
  background: black;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.seek_slider::-webkit-slider-thumb,
.volumeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: black;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.seek_slider:hover,
.volumeSlider:hover {
  cursor: pointer;
  opacity: 1;
}

.volumeSlider {
  width: 30%;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 6px;

  background-clip: content-box;
  border: 1.5px solid #bbbbbb;
  border-radius: 6px;
  background-color: #e7e6e7;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;

  &:checked {
   background-color: black;
  }

  &:focus{
   outline: none !important;
  }
}

.checkbox label {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 12%;
}
